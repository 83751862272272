import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import React, { useState } from 'react';
import {DebounceInput} from 'react-debounce-input';

const KeyResult = (props) => {
  const {no, keyResult} = props;
  const [result, setResult] = useState(keyResult.result || '');
  const syncKeyResult = useStoreActions(
    actions => actions.updateKeyResult
  );
  const deleteKeyResult = useStoreActions(
    actions => actions.removeKeyResult
  );
  const insertKeyResult = useStoreActions(
    actions => actions.addKeyResult
  );
  const updateResult = (updatedResult) => {
    setResult(updatedResult);
    syncKeyResult({ id: keyResult.id, okr_id: keyResult.okr_id, result: updatedResult})
  }

  const addNewOnEnter = (event) => {
    if(event.key === 'Enter'){
      insertKeyResult(keyResult.okr_id);
    }
  }

  return (
    <>
      <div className="row">
        <div className="pull-left">{no}.</div>
        <div className="pull-left result-input-container">
          <DebounceInput
            // minLength={15}
            debounceTimeout={300}
            value={result}
            placeholder="Please set key result"
            onChange={event => updateResult(event.target.value) }
            className="okr-objective-input"
            autoFocus={keyResult.focus}
            onKeyPress={event => addNewOnEnter(event)}
          />
        </div>
        <div className="pull-left">
          <a onClick={ () => deleteKeyResult(keyResult) } >
            <i className="fa fa-trash key-result-trash" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </>
  );
}

export default KeyResult;