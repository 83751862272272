import { createStore, thunk, action } from 'easy-peasy';
import { addOKR, getOKRs, updateOKR } from '../Services/OkrService';
import { addKeyResult, updateKeyResult, deleteKeyResult } from '../Services/KeyResultService';
import _ from 'lodash';

const store = createStore({
  okrs: [],
  isAdmin: false,
  fetchOkrs: thunk(async (actions, payload) => {
    const resp = await getOKRs();
    const okrs = resp.data.okrs;
    actions.setOkrs(okrs);
  }),
  setOkrs: action((state, payload) => {
    state.okrs = payload;
  }),
  addOKR: thunk(async (actions, payload) => {
    const resp = await addOKR();
    const okr = resp.data.okr;
    actions.insertOkr(okr);
  }),
  insertOkr: action((state, payload) => {
    payload["focus"] = true;
    state.okrs.push(payload)
  }),
  updateOKR: thunk(async (actions, payload) => {
    const resp = await updateOKR(payload);
    const okr = resp.data.okr;
    actions.updateOkr(okr);
  }),
  updateOkr: action((state, payload) => {
    const okrIndex = _.findIndex(state.okrs, {id: payload.id});
    state.okrs[okrIndex] = payload;
  }),
  addKeyResult: thunk(async (actions, payload) => {
    const resp = await addKeyResult(payload);
    const okr = resp.data.key_result;
    actions.insertKeyResult(okr);
  }),
  insertKeyResult: action((state, payload) => {
    payload["focus"] = true;
    const okrIndex = _.findIndex(state.okrs, {id: payload.okr_id});
    state.okrs[okrIndex].key_results.push(payload)
  }),
  updateKeyResult: thunk(async (actions, payload) => {
    const resp = await updateKeyResult(payload);
    const keyResult = resp.data.key_result;
    actions.replaceOkr(keyResult);
  }),
  replaceOkr: action((state, payload) => {
    const okrIndex = _.findIndex(state.okrs, {id: payload.okr_id});
    const keyResultIndex = _.findIndex(state.okrs[okrIndex].key_results, {id: payload.id});
    state.okrs[okrIndex].key_results[keyResultIndex] = payload;
  }),
  removeKeyResult: thunk(async (actions, payload) => {
    const resp = await deleteKeyResult(payload);
    const keyResult = resp.data.key_result;
    actions.deleteKeyResult(keyResult);
  }),
  deleteKeyResult: action((state, payload) => {
    const okrIndex = _.findIndex(state.okrs, {id: payload.okr_id});
    const keyResultIndex = _.findIndex(state.okrs[okrIndex].key_results, {id: payload.id});
    state.okrs[okrIndex].key_results.splice(keyResultIndex, 1);
  }),
  setIsAdmin: action( (state, payload) => {
    state.isAdmin = payload
  })
});

export default store;