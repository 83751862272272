import PropTypes from 'prop-types';
import React from 'react';
import { useStoreActions } from 'easy-peasy';

const AddOKR = (props) => {
  const insertOKR = useStoreActions(
    actions => actions.addOKR
  );
  return (
    <div className="row mt-3">
      <div className="col-12 text-right">
        <button onClick={ () => insertOKR()} className="btn btn-primary" >Add OKR</button>
      </div>
    </div>
  );
}

export default AddOKR;