import ReactOnRails from 'react-on-rails';
import OKRWrapper from '../bundles/OKR/OKRWrapper';
import OKRs from '../bundles/OKR/OKRs';
import OKR from '../bundles/OKR/OKR';
import AddOKR from '../bundles/OKR/AddOKR';
import OKRTabs from '../bundles/OKR/AddOKR';
import KeyResults from '../bundles/KeyResult/KeyResults';
import KeyResult from '../bundles/KeyResult/KeyResult';
import AddKeyResult from '../bundles/KeyResult/AddKeyResult';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  OKRWrapper,
  OKRs,
  OKR,
  AddOKR,
  OKRTabs,
  KeyResults,
  KeyResult,
  AddKeyResult,
});
