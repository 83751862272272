import axios from 'axios';

export const addKeyResult = (okrID) => {
  return axios.post(`/api/v1/okrs/${okrID}/key_results`, {});
}

export const updateKeyResult = (keyResult) => {
  return axios.patch(`/api/v1/okrs/${keyResult.okr_id}/key_results/${keyResult.id}`, { key_result: { result: keyResult.result }  });
}

export const deleteKeyResult = (keyResult) => {
  return axios.delete(`/api/v1/okrs/${keyResult.okr_id}/key_results/${keyResult.id}`, {});
}
