import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { StoreProvider } from 'easy-peasy';
import store from '../Store/Store';
import { useStoreActions } from 'easy-peasy';
import OKRs from './OKRs';

const OKRWrapper = (props) => {

  return (
    <StoreProvider store={store}>
      <OKRs isAdmin={props.isAdmin} />
    </StoreProvider>
  )
}

export default OKRWrapper;