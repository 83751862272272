import axios from 'axios';

export const getOKRs = () => {
  return axios.get('/api/v1/okrs');
}

export const addOKR = () => {
  return axios.post('/api/v1/okrs', {});
}

export const updateOKR = (payload) => {
  return axios.patch(`/api/v1/okrs/${payload.id}`, { okr: payload.okr });
}