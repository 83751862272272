import PropTypes from 'prop-types';
import React, { useState } from 'react';
import KeyResults from '../KeyResult/KeyResults';

const OKRTabs = ({okr}) => {
  const [tab, setTab] = useState('keyResults');
  return (
    <div className="card">
      <div className="card-body min-300">
        <div className="row">
          <div className="col-2">
            <ul className="nav nav-pills nav-stacked">
              <li className="nav-item">
                <a className={`nav-link ${ (tab === 'keyResults') && 'active'}`} onClick={ () => setTab('keyResults') } >Key Results</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${ (tab === 'comments') && 'active'}`} onClick={ () => setTab('comments') } >Comments</a>
              </li>
            </ul>
          </div>
          <div className="col-10">
            {(tab === 'keyResults') && <KeyResults okr={okr} /> }
          </div>
        </div>
      </div>
    </div>
  );
}

export default OKRTabs;