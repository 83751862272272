import PropTypes from 'prop-types';
import React from 'react';
import KeyResult from './KeyResult';
import AddKeyResult from './AddKeyResult';

const KeyResults = ({okr}) => {
  return (
    <>
      { (okr.key_results.length === 0) ?
        <div>Please click add okr to add your first key result.</div>
      : okr.key_results.map((keyResult, index) => <KeyResult keyResult={keyResult} key={`keyResult${keyResult.id}`} no={index+1} /> ) }
      <AddKeyResult okr={okr} />
    </>
  );
}

export default KeyResults;