import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import OKR from './OKR';
import AddOKR from './AddOKR';

const OKRs = ({ isAdmin }) => {
  const getOkrs = useStoreActions(actions => actions.fetchOkrs);
  const okrs = useStoreState(state => state.okrs) || [];
  const setIsAdmin = useStoreActions( actions => actions.setIsAdmin);

  useEffect(() => {
    getOkrs();
    setIsAdmin(isAdmin);
  }, []);
  return (
    <>
      { (okrs.length === 0) ?
        <div className="card">
          <div className="card-body">
              Please click add okr button to add your first objective for the term.
            </div>
          </div>
      : okrs.map((okr) => <OKR okr={okr} key={okr.id} /> ) }
      <AddOKR />
    </>
  );
}

export default OKRs;