import PropTypes from "prop-types";
import { useStoreActions } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { Collapse } from "react-collapse";
import OKRTabs from "./OKRTabs";

const OKR = ({ okr }) => {
  const [objective, setObjective] = useState(okr.objective || "");
  const [expand, setExpand] = useState(false);
  const updateOKR = useStoreActions((actions) => actions.updateOKR);

  const updateObjective = (updatedObjective) => {
    setObjective(updatedObjective);
    updateOKR({ id: okr.id, okr: { objective: updatedObjective } });
  };

  const insertOKR = useStoreActions((actions) => actions.addOKR);

  const addNewOnEnter = (event) => {
    if (event.key === "Enter") {
      insertOKR();
    }
  };

  const isApproved = () => {
    return okr.status === "approve";
  };

  const isDraft = () => {
    return okr.status === "draft";
  };

  const changeStatus = (status) => {
    updateOKR({ id: okr.id, state: status });
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-1 text-center">
              <a onClick={() => setExpand(!expand)}>
                {expand ? (
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                )}
              </a>
            </div>
            <div className="col-8">
              {isDraft() ? (
                <DebounceInput
                  // minLength={15}
                  debounceTimeout={300}
                  value={objective}
                  placeholder="Please set objective"
                  onChange={(event) => updateObjective(event.target.value)}
                  inputRef={`objective_ref_${okr.id}`}
                  className="okr-objective-input"
                  autoFocus={okr.focus}
                  onKeyPress={(event) => addNewOnEnter(event)}
                />
              ) : (
                okr.objective
              )}
            </div>
            <div className="col-3">
              {isDraft() && (
                <>
                  <button className="btn btn-sm btn-primary ml-1" onClick={ () => changeStatus('approve') } >Approve</button>
                  <button className="btn btn-sm btn-danger ml-1" >Delete</button>
                </>
              )}
              {isApproved() && (
                <button className="btn btn-sm btn-primary ml-1">Achievement</button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Collapse isOpened={expand}>
        <OKRTabs okr={okr} />
      </Collapse>
    </>
  );
};

OKR.propTypes = {
  okr: PropTypes.shape({
    id: PropTypes.number,
    objective: PropTypes.string,
    status: PropTypes.string,
    focus: PropTypes.boolean,
  }),
};

export default OKR;
