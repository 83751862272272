import PropTypes from 'prop-types';
import React from 'react';
import { useStoreActions } from 'easy-peasy';

const AddKeyResult = ({okr}) => {
  const insertKeyResult = useStoreActions(
    actions => actions.addKeyResult
  );
  return (
    <div className="row mt-3">
      <div className="col-12 text-right">
        <button onClick={ () => insertKeyResult(okr.id)} className="btn btn-primary" >Add Key Result</button>
      </div>
    </div>
  );
}

export default AddKeyResult;